import { Component, input, OnInit } from '@angular/core';

export type ButtonTheme = 'default' | 'primary';

@Component({
  selector:
    'a[app-filled-button], button[app-filled-button], input[type="submit"][app-filled-button]',
  templateUrl: './filled-button.component.html',
  styleUrls: ['./filled-button.component.scss'],
  host: {
    '[class]': 'theme()',
  },
  standalone: true,
})
export class FilledButtonComponent implements OnInit {
  theme = input<ButtonTheme>('default');

  constructor() {}

  ngOnInit(): void {}
}
