import { Component, OnInit } from '@angular/core';

@Component({
  selector:
    'a[app-outlined-button], button[app-outlined-button], input[type="submit"][app-outlined-button]',
  templateUrl: './outlined-button.component.html',
  styleUrls: ['./outlined-button.component.scss'],
  standalone: true,
})
export class OutlinedButtonComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
